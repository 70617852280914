<template>
  <div class="ready-articles">
    <section class="ready-articles-block block">
      <div class="ready-articles-header block-wrapper">
        <h1 class="title articles-list-title">Public articles</h1>
        <Dropdown placeholder="All" :with-empty="true" :active="mapSelectedUser" :list="mapUsers"
                  @selected="selectUser"/>
      </div>
      <div class="table-container">
        <Preloader v-if="pending"/>
        <table v-if="list.length > 0" class="ready-articles-table articles-list-table">
          <thead class="ready-articles-table-header">
          <tr>
            <td></td>
            <td>Title</td>
            <td>Status</td>
            <td colspan="2">Date add</td>
          </tr>
          </thead>
          <tbody class="ready-articles-table-body">
          <tr v-for="(article, idx) in list" :key="`public-${idx}`">
            <td>
<!--              <Checkbox :value="checkedArticle.indexOf(item.id) >= 0" @input="check(item.id)"/>-->
            </td>
            <td>
              <div class="ready-articles-item-title df">
                <span class="counter">#{{ idx + 1 + ((page - 1) * quantity) }}</span>
                <span class="lang">{{ article.version.language }}</span>
                <router-link :to="`/dashboard/articles/${article.id}`">{{ article.version.title }}</router-link>
              </div>
            </td>
            <td
              class="col article-status"
              :class="{
              'c-green': article.status === 'READY' || article.status === 'PUB',
              'c-red': article.status === 'LOCKED'
            }"
            >{{ article.status === 'UNLOCKED' ? 'Wait' : article.status.toLowerCase() }}
            </td>
            <td>
              {{ article.ready_date ? $moment(article.ready_date).format('DD.MM.YYYY') : '--' }}
            </td>
            <td style="text-align:right;">
              <button @click="remove([article.id])" class="ready-articles-btn-remove">
                <Delete/>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        <div v-else class="table-empty">
          <p v-if="!pending">Empty</p>
        </div>
        <APagination
          ref="pagination"
          :visible="list.length > 0"
          :page="page"
          :count="paginationPages"
          :callback="setPagination"
        />
      </div>
    </section>
    <div class="articles-list-info-container">
      <p class="articles-list-info">
        <span>WT</span> - Etiam ultrices dictum ligula, ac auctor tortor pharetra et. Donec ullamcorper nibh aliquet,
        rhoncus massa ac, efficitur mi.
      </p>
      <p class="articles-list-info">
        <span>UWA</span> - Etiam ultrices dictum ligula, ac auctor tortor pharetra et. Donec ullamcorper nibh aliquet,
        rhoncus massa ac, efficitur mi.
      </p>
      <p class="articles-list-info">
        <span>UWW</span> - Etiam ultrices dictum ligula, ac auctor tortor pharetra et. Donec ullamcorper nibh aliquet,
        rhoncus massa ac, efficitur mi.
      </p>
    </div>
  </div>
</template>

<script>
import Delete from '@/assets/icons/delete.svg';
import {mapActions} from 'vuex';
import {eventBus} from '@/main';
import Preloader from '@/components/common/Preloader';

export default {
  name: "ReadyArticlesPage",
  components: {
    Dropdown: () => import('@/components/common/ADropdown'),
    // Checkbox: () => import('@/components/common/ACheckbox'),
    APagination: () => import('@/components/common/APagination'),
    Delete,
    Preloader
  },
  data() {
    return {
      list: [],
      page: 1,
      quantity: 30,
      total: 0,
      pending: false,
      checkedArticle: [],
      users: [],
      selectedUser: null
    }
  },
  computed: {
    mapSelectedUser() {
      return this.selectedUser ? {
        name: this.selectedUser.email,
        value: this.selectedUser.id
      } : null;
    },
    mapUsers() {
      return this.users.filter(u => u.role === 'USER').map(u => ({
        name: u.email,
        value: u.id
      }))
    },
    paginationPages() {
      let pageNum = Math.trunc(this.total / this.quantity);
      if((this.total % this.quantity) > 0) {
        pageNum++;
      }
      return pageNum;
    },
  },
  watch: {
    $route: {
      async handler(state, prevState) {
        if (state.query.user !== prevState.query.user) {
          await this.loadArticles();
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions(['getArticlesUser', 'getPublicArticles', 'deleteArticles', 'getAllUsers']),
    // check(id) {
    //   const idx = this.checkedArticle.indexOf(id);
    //   if (idx >= 0) {
    //     this.checkedArticle.splice(idx, 1);
    //   } else {
    //     this.checkedArticle.push(id);
    //   }
    // },
    // checkAll() {
    //   this.checkedArticle = this.checkedArticle.length === 0 ? this.list.map(x => x.id) : [];
    // },
    selectUser(user) {
      this.selectedUser = user ? this.users.find(u => u.id === user.value) : null;
      if (this.selectedUser) {
        this.$router.push({query: {...this.$route.query, user: this.selectedUser.id}})
      } else {
        const query = {...this.$route.query, user: null};
        delete query.user;
        this.$router.push({query})
      }
    },
    async remove(ids) {
      this.pending = true;
      await this.deleteArticles(ids).then(async () => {
        this.$notify({
          title: 'Delete ready articles',
          text: 'Ready article deleted successfully',
          type: 'success'
        });
        await this.loadArticles();
      }).catch(error => {
        this.pending = false;
        this.$notify({
          title: 'Delete ready articles',
          text: error.response.data.message,
          type: 'error'
        });
      });
    },
    async setPagination(num) {
      this.page = num;
      await this.loadArticles();
    },
    async loadArticles() {
      this.pending = true;
      const {user} = this.$route.query;
      if(this.selectedUser || user) {
        await this.getArticlesUser({
          per_page: this.quantity,
          current_page: this.page,
          id: this.selectedUser.id || user,
          status: 'PUB'
        }).then(({data, pagination}) => {
          this.pending = false;
          this.total = pagination.total;
          this.list = data;

          if (data.length === 0 && this.page > 1) {
            this.$refs['pagination'].onChangePage(1);
          }
        }).catch(error => {
          this.pending = false;
          this.$notify({
            title: 'Public Articles list',
            text: error.response.data.message,
            type: 'error'
          });
        });
      } else {
        await this.getPublicArticles({per_page: this.quantity, current_page: this.page}).then(({data, pagination}) => {
          this.pending = false;
          this.total = pagination.total;
          this.list = data;

          if (data.length === 0 && this.page > 1) {
            this.$refs['pagination'].onChangePage(1);
          }
        }).catch(error => {
          this.pending = false;
          this.$notify({
            title: 'Public Articles List',
            text: error.response.data.message,
            type: 'error'
          });
        });
      }
    },
    async getUsers() {
      await this.getAllUsers().then(data => {
        this.users = data
      }).catch(error => {
        this.$notify({
          title: 'Get users',
          text: error.response.data.message,
          type: 'error'
        });
      });
    },
  },
  async created() {
    await this.getUsers();
    const {user} = this.$route.query
    if (user) {
      const fUser = this.users.find(u => u.id === user);
      if (fUser && fUser.role === 'USER') {
        this.selectedUser = fUser;
      } else {
        this.$router.replace('/dashboard/public')
      }
    }
    eventBus.$on('onLoadArticles', async data => {
      await this.loadArticles();
    });
  }
}
</script>

<style scoped>

</style>
